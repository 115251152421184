import React from 'react';
import { FaClock, FaMapMarkerAlt } from "react-icons/fa";
import { MapContainer, TileLayer, Popup } from 'react-leaflet';
import { Marker } from 'react-leaflet/Marker';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

function StoreInfo() {
    const position = [40.768650, -73.647790];
    const customMarkerIcon = L.icon({
        iconUrl: './map-pin.svg',
        iconSize: [40, 60],
        iconAnchor: [20, 45],
        popupAnchor: [1, -25],
    });

    return (
        <div className="flex flex-col md:flex-row w-full bg-gray-100 py-16 px-8" id="store-info">
            {/* Store Hours Section */}
            <div
                className="flex-1 flex flex-col justify-center items-center text-center md:pr-8 border-b md:border-b-0 md:border-r border-gray-300 pb-8 md:pb-0">
                <div className="flex items-center mb-4 space-x-3">
                    <FaClock className="text-4xl text-red-600"/>
                    <h2 className="text-3xl font-bold text-gray-900">Store Hours</h2>
                </div>
                <ul className="grid gap-2 text-lg text-gray-800 leading-loose">
                    <li className="grid grid-cols-2">
                        <span className={"text-left"}>Monday:</span> <span className="font-semibold text-right">9:30 AM - 6:00 PM</span>
                    </li>
                    <li className="grid grid-cols-2">
                        <span className={"text-left"}>Tuesday:</span> <span className="font-semibold text-right">9:30 AM - 5:00 PM</span>
                    </li>
                    <li className="grid grid-cols-2">
                        <span className={"text-left"}>Wednesday:</span> <span className="font-semibold text-right">9:30 AM - 7:00 PM</span>
                    </li>
                    <li className="grid grid-cols-2">
                        <span className={"text-left"}>Thursday:</span> <span className="font-semibold text-right">9:30 AM - 5:00 PM</span>
                    </li>
                    <li className="grid grid-cols-2">
                        <span className={"text-left"}>Friday:</span> <span className="font-semibold text-right">9:30 AM - 5:00 PM</span>
                    </li>
                    <li className="grid grid-cols-2">
                        <span className={"text-left"}>Saturday:</span> <span className="font-semibold text-right">9:30 AM - 4:00 PM</span>
                    </li>
                    <li className="grid grid-cols-2">
                        <span className={"text-left"}>Sunday:</span> <span
                        className="font-semibold text-red-600 text-right">Closed</span>
                    </li>
                </ul>
            </div>

            {/* Location Section */}
            <div className="flex-1 flex flex-col justify-center items-center text-center mt-8 md:mt-0 md:pl-8">
                <div className="flex items-center mb-4 space-x-3">
                    <FaMapMarkerAlt className="text-4xl text-green-600"/>
                    <h2 className="text-3xl font-bold text-gray-900">Our Location</h2>
                </div>
                <div className="rounded-lg overflow-hidden shadow-lg border border-gray-300 w-full max-w-md z-10">
                    <MapContainer
                        center={position}
                        zoom={16}
                        scrollWheelZoom={false}
                        className="h-72 w-full"
                    >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />
                        <Marker position={position} icon={customMarkerIcon}>
                            <Popup>
                                888 Willis Ave, Albertson, NY 11507
                            </Popup>
                        </Marker>
                    </MapContainer>
                </div>
                <p className="mt-4 text-lg text-gray-800">
                    <a
                        href="https://maps.app.goo.gl/Q7c7u27aYodsmJqJ9"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:text-blue-800 transition-all font-semibold"
                    >
                        888 Willis Ave, Albertson, NY 11507
                    </a>
                </p>
            </div>
        </div>
    );
}

export default StoreInfo;