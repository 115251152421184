import React from 'react';

function Footer() {
    return (
        <footer className="bg-gray-900 text-white mt-16 py-8">
            <div className="max-w-7xl mx-auto px-6 text-center">
                <div className="flex flex-col md:flex-row items-center justify-between text-center md:text-left space-y-4 md:space-y-0">
                    <p className="text-md text-gray-400 tracking-wide">
                        © {new Date().getFullYear()} Vision World Albertson. All rights reserved.
                    </p>
                    <p className="text-md text-gray-400 tracking-wide">
                        Powered by{' '}
                        <a
                            href="https://ax2tech.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-400 hover:text-blue-500 transition-all duration-300"
                        >
                            Ax2 Technologies
                        </a>
                    </p>
                </div>
                <div className="mt-6 border-t border-gray-700 pt-4">
                    <ul className="flex justify-center space-x-6">
                        <li><a href="#" className="text-gray-400 hover:text-white transition-all duration-300">Privacy Policy</a></li>
                        <li><a href="#" className="text-gray-400 hover:text-white transition-all duration-300">Terms of Service</a></li>
                        <li><a href="#contact-us" className="text-gray-400 hover:text-white transition-all duration-300">Contact</a></li>
                    </ul>
                </div>
            </div>
        </footer>
    );
}

export default Footer;