import React from 'react';

function AboutUs() {
    return (
        <section className="bg-gray-100 py-16 px-6" id="about-us">
            <div className="max-w-7xl mx-auto text-center">
                <h2 className="text-4xl font-bold text-gray-900 mb-8 tracking-wide">About Us</h2>

                <p className="text-lg text-gray-700 leading-relaxed max-w-3xl mx-auto">
                    At Vision World Albertson, we are committed to providing top-quality eye care services
                    and a wide range of stylish eyewear. Our dedicated team works tirelessly to ensure
                    each customer finds the perfect pair of glasses that suits their lifestyle and needs.
                </p>

                <div className="mt-12">
                    <img
                        src="./photos/team-photo.jpeg"
                        alt="Our Team"
                        className="rounded-lg shadow-lg mx-auto w-full max-w-4xl"
                    />
                </div>

                <div className="mt-8 max-w-4xl mx-auto text-lg text-gray-700 leading-relaxed">
                    <p className="mb-4">
                        Our expert optometrists and friendly staff are here to help you every step of the way.
                        Whether you need an eye exam, a contact lens fitting, or the latest in designer eyewear,
                        we're your go-to destination for all things vision.
                    </p>
                    <p>
                        Visit us today and experience our commitment to excellence!
                    </p>
                </div>
            </div>
        </section>
    );
}

export default AboutUs;