import React from 'react';

function HeroSection() {
    return (
        <div
            className="relative w-full h-screen bg-cover bg-center flex items-center justify-center text-center"
            style={{ backgroundImage: `url(./photos/cover.jpeg)` }}
            id="hero"
        >
            {/* Gradient Overlay */}
            <div className="absolute inset-0 bg-gradient-to-b from-black/70 via-black/40 to-black/70 backdrop-blur-sm"></div>

            {/* Hero Content */}
            <div className="relative z-10 max-w-3xl px-6">
                <h1 className="text-white text-5xl md:text-7xl font-extrabold tracking-wide drop-shadow-md">
                    Vision World of Albertson
                </h1>
                <p className="text-white text-lg md:text-2xl mt-4 font-light tracking-wider">
                    Discover your perfect pair of glasses with us!
                </p>

                {/* Call to Action Button */}
                <a
                    href="#store-info"
                    className="mt-8 inline-block bg-white text-black font-medium text-lg md:text-xl px-8 py-3 rounded-full shadow-lg hover:bg-opacity-90 transition-all duration-300"
                >
                    Visit Us
                </a>
            </div>
        </div>
    );
}

export default HeroSection;