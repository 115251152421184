import React from 'react';
import { FaGlasses, FaEye, FaStethoscope } from 'react-icons/fa';

function FeaturesRow() {
    const features = [
        {
            ico: <FaGlasses className="text-5xl text-blue-600 transition-all duration-300 group-hover:scale-110" />,
            header: "Shop Glasses",
            description: "Explore our extensive range of stylish and functional eyewear. Find frames and lenses that fit your lifestyle and fashion sense perfectly."
        },
        {
            ico: <FaEye className="text-5xl text-pink-600 transition-all duration-300 group-hover:scale-110" />,
            header: "Contacts",
            description: "Discover the perfect contact lenses with a personalized fitting session at our store. Comfort and clarity are just a visit away."
        },
        {
            ico: <FaStethoscope className="text-5xl text-teal-600 transition-all duration-300 group-hover:scale-110" />,
            header: "Eye Exams",
            description: "Book an eye exam with our expert optometrists to ensure your vision health. We offer comprehensive eye care services Monday to Saturday."
        }
    ];

    return (
        <div className="max-w-7xl mx-auto px-6 py-16 grid grid-cols-1 md:grid-cols-3 gap-12">
            {features.map((feature, index) => (
                <div
                    key={index}
                    className="group flex flex-col items-center text-center bg-gray-100 rounded-lg p-10 shadow-lg border border-gray-300 transition-all duration-300 hover:shadow-2xl hover:scale-105"
                >
                    <div className="mb-4">{feature.ico}</div>
                    <h3 className="text-3xl font-bold text-gray-900 tracking-wide">{feature.header}</h3>
                    <p className="text-lg text-gray-800 mt-3 leading-relaxed">
                        {feature.description}
                    </p>
                </div>
            ))}
        </div>
    );
}

export default FeaturesRow;