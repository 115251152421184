import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';

function Navbar() {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const heroSection = document.getElementById('hero');
            const heroHeight = heroSection ? heroSection.offsetHeight : 0;

            if (window.scrollY > heroHeight) {
                setIsScrolled(true);
                setIsOpen(false);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <nav
            className={`fixed w-full z-50 transition-transform duration-700 ease-in-out ${
                isOpen
                    ? 'bg-black bg-opacity-100'
                    : isScrolled
                        ? 'bg-black bg-opacity-80 shadow-lg'
                        : 'bg-transparent'
            }`}
        >
            <div className="max-w-7xl mx-auto px-6">
                <div className="flex justify-between items-center py-4">
                    <a href="#" className="text-2xl font-light tracking-wide text-white">Vision World</a>
                    <button
                        onClick={() => setIsOpen(!isOpen)}
                        className="text-white text-3xl md:hidden focus:outline-none transition-all duration-300"
                    >
                        {isOpen ? <FaTimes className="text-gray-300" /> : <FaBars className="text-gray-300" />}
                    </button>

                    {/* Mobile Menu */}
                    <div
                        className={`absolute inset-x-0 top-full transition-transform duration-700 ease-in-out ${
                            isOpen ? 'translate-y-0 opacity-100' : '-translate-y-5 opacity-0 pointer-events-none'
                        } bg-black shadow-lg rounded-b-lg`}
                    >
                        <div className="flex flex-col items-center py-6 space-y-6">
                            <a href="#" className="text-xl text-gray-200 hover:text-white transition" onClick={() => setIsOpen(false)}>Home</a>
                            <a href="#store-info" className="text-xl text-gray-200 hover:text-white transition" onClick={() => setIsOpen(false)}>Visit Us</a>
                            <a href="#contact-us" className="text-xl text-gray-200 hover:text-white transition" onClick={() => setIsOpen(false)}>Contact</a>
                        </div>
                    </div>

                    {/* Desktop Menu */}
                    <div className="hidden md:flex space-x-8 text-lg">
                        <a href="#" className="text-gray-300 hover:text-white transition-all duration-200">Home</a>
                        <a href="#store-info" className="text-gray-300 hover:text-white transition-all duration-200">Visit Us</a>
                        <a href="#contact-us" className="text-gray-300 hover:text-white transition-all duration-200">Contact</a>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;