import React, { useState } from 'react';
import MarketingSignup from './MarketingSignup';

function ContactUs() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const apiUrl = `${process.env.REACT_APP_APIUrl}/ContactUsForm`;
            const clientId = process.env.REACT_APP_ClientId;

            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'ClientId': clientId,
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setFormData({ name: '', email: '', message: '' });
                alert("Message sent successfully");
            } else {
                console.error('Form submission failed', response.statusText);
            }
        } catch (error) {
            console.error('Error occurred during form submission:', error);
        }
    };

    return (
        <div className="max-w-6xl mx-auto px-6 py-16" id="contact-us">
            <h2 className="text-4xl font-bold text-center text-gray-900 mb-12 tracking-wide">
                Contact Us
            </h2>

            <div className="flex flex-col md:flex-row gap-12">
                {/* Form Section */}
                <div className="flex-1 bg-white p-8 rounded-lg shadow-md border border-gray-300">
                    <h3 className="text-2xl font-bold text-gray-900 mb-6 text-center">Online Inquiries</h3>
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div>
                            <label htmlFor="name" className="block text-sm font-medium text-gray-800">Name</label>
                            <input
                                type="text"
                                name="name"
                                id="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="mt-2 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-800">Email</label>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="mt-2 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="message" className="block text-sm font-medium text-gray-800">Message</label>
                            <textarea
                                name="message"
                                id="message"
                                value={formData.message}
                                onChange={handleChange}
                                rows="4"
                                className="mt-2 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                required
                            ></textarea>
                        </div>
                        <button
                            type="submit"
                            className="w-full bg-blue-600 text-white font-bold py-3 rounded-lg shadow-md hover:bg-blue-700 transition duration-200"
                        >
                            Send Message
                        </button>
                    </form>
                </div>

                {/* Contact Details Section */}
                <div className="flex-1 flex flex-col items-center text-center">
                    <h3 className="text-2xl font-bold text-gray-900 mb-6">Contact Details</h3>
                    <p className="text-lg text-gray-800 mb-2"><strong>Phone:</strong> <a href="tel:+15167476363" className="text-blue-500 hover:text-blue-700">+1 (516) 747-6363</a></p>
                    <p className="text-lg text-gray-800 mb-2"><strong>Email:</strong> <a href="mailto:visionworldalbertsonny@gmail.com" className="text-blue-500 hover:text-blue-700">visionworldalbertsonny@gmail.com</a></p>
                    <p className="text-lg text-gray-800 mb-6"><strong>Address:</strong> <a href="https://maps.app.goo.gl/Q7c7u27aYodsmJqJ9" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">888 Willis Ave, Albertson, NY 11507</a></p>

                    <MarketingSignup />
                </div>
            </div>
        </div>
    );
}

export default ContactUs;