import React, { useState, useEffect, useRef } from 'react';

function TopBrands() {
    const brands = [
        { name: 'Gucci', logo: './brands/gucci.svg', link: 'https://www.gucci.com' },
        { name: 'Prada', logo: './brands/prada.svg', link: 'https://www.prada.com' },
        { name: 'Ray-Ban', logo: './brands/ray-ban.svg', link: 'https://www.ray-ban.com' },
        { name: 'Oakley', logo: './brands/oakley.svg', link: 'https://www.oakley.com' },
        { name: 'Tom Ford', logo: './brands/tomford.svg', link: 'https://www.tomford.com' }
    ];

    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect(); // Trigger animation once
                }
            },
            {
                rootMargin: '0px',
                threshold: 0.3 // Starts animation a bit earlier
            }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => observer.disconnect(); // Cleanup observer
    }, []);

    return (
        <div ref={ref} className="bg-white py-16">
            <div className="max-w-7xl mx-auto px-6">
                <h2 className="text-4xl font-bold text-gray-900 text-center mb-12 tracking-wide">
                    Top Brands
                </h2>
                <div className="flex flex-wrap justify-center items-center gap-16">
                    {brands.map((brand, index) => (
                        <a
                            key={index}
                            href={brand.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={`hover:scale-110 transition-all duration-700 ease-in-out transform ${
                                isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
                            }`}
                        >
                            <img
                                src={brand.logo}
                                alt={`${brand.name} Logo`}
                                className="h-24 md:h-36 transition-opacity duration-700 ease-in-out"
                            />
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default TopBrands;