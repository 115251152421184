import React, { useState } from 'react';

function MarketingSignup() {
    const [email, setEmail] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        const requestBody = {
            email: email
        };

        const apiUrl = process.env.REACT_APP_APIUrl;
        const clientId = process.env.REACT_APP_ClientId;

        try {
            const response = await fetch(`${apiUrl}/EmailSignUp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'ClientId': clientId,
                },
                body: JSON.stringify(requestBody),
            });

            if (response.ok) {
                alert(`Thank you for subscribing, ${email}!`);
                setEmail('');
            } else {
                alert('Failed to Subscribe. You may already be a subscriber!');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred. Please try again.');
        }
    };

    return (
        <div className="bg-gray-100 p-6 rounded-lg shadow-md max-w-md w-full">
            <h3 className="text-2xl font-bold text-gray-900 text-center mb-4">Join Our Newsletter</h3>
            <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="px-4 py-3 border border-gray-300 rounded-lg text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                />
                <button
                    type="submit"
                    className="bg-blue-600 text-white font-bold py-3 rounded-lg shadow-md hover:bg-blue-700 transition duration-200"
                >
                    Subscribe
                </button>
            </form>
            <p className="text-sm text-gray-700 mt-4 text-center">
                Subscribe to get special offers, free giveaways, and once-in-a-lifetime deals.
            </p>
        </div>
    );
}

export default MarketingSignup;